import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { get, isNull, map } from 'lodash';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import { List, Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {},
  title: {
    padding: theme.spacing(4, 0, 0),
  },
  preparationIngredients: {
    paddingLeft: theme.spacing(3),
  },
  ingredient: {},
  text: {},
  list: {
    padding: theme.spacing(0, 0, 4, 3),
  },
  listItem: {
    '&::marker ': {
      color: theme.palette.link.link,
    },
  },
  richtextListItem: {
    marginBottom: '0 !important',
  },
  listRoot: {
    paddingTop: theme.spacing(2),
  },
});

function Ingredient({ classes, className, data, richText: RichText }) {
  const { t } = useTranslation();
  const inspectorProps = useContentfulInspectorMode();
  const preparationIngredients = get(data, 'preparationIngredients');
  const ingredients = get(data, 'ingredients');
  const contentfulFieldId = ingredients ? 'ingredients' : 'preparationIngredients';

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="h3" className={classes.title}>
        {t('PreparationIngredients.title')}
      </Typography>
      {!isNull(ingredients) && (
        <RichText
          textData={ingredients}
          classes={{
            root: classes.listRoot,
            ulList: classes.list,
            listItem: classes.listItem,
            paragraph: classes.richtextListItem,
          }}
          {...inspectorProps({
            entryId: get(data, 'contentful_id'),
            fieldId: contentfulFieldId,
          })}
        />
      )}
      {isNull(ingredients) && (
        <List
          className={classes.preparationIngredients}
          {...inspectorProps({
            entryId: get(data, 'contentful_id'),
            fieldId: contentfulFieldId,
          })}>
          {map(preparationIngredients, (PreparationIngredient, index) => {
            const ingredient = get(PreparationIngredient, 'ingredient');
            return (
              <li key={index} className={classes.ingredients}>
                <Typography className={classes.text}>{ingredient}</Typography>
              </li>
            );
          })}
        </List>
      )}
    </div>
  );
}

Ingredient.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

Ingredient.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(Ingredient);
