import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import { withStyles } from '@material-ui/core';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import IframeResizer from 'iframe-resizer-react';
import isEmpty from 'lodash/isEmpty';

const styles = () => ({
  root: {},
  iframe: {
    width: '1px',
    minWidth: '100%',
    border: 'none',
    minHeight: ({ data }) => {
      const minimumHeight = get(data, 'minimumHeight', null);
      return minimumHeight;
    },
  },
});

// eslint-disable-next-line react/prop-types
function Iframe({ type, ...otherProps }) {
  const iframeRef = useRef(null);

  switch (type) {
    case 'fullHeight': {
      return (
        <IframeResizer
          {...otherProps}
          title={type}
          forwardRef={iframeRef}
          checkOrigin={false}
          heightCalculationMethod="lowestElement"
          log={false}
          inPageLinks
        />
      );
    }

    default: {
      return <iframe {...otherProps} title={type} />;
    }
  }
}

function IFrameModule({ classes, className, data }) {
  const inspectorProps = useContentfulInspectorMode();
  const url = get(data, 'url');
  // const type = camelCase(get(data, 'iFrameType.iFrameType'));

  return (
    <div
      className={clsx(classes.root, className)}
      {...inspectorProps({
        entryId: get(data, 'contentful_id'),
        fieldId: 'url',
        locale: 'en-US',
      })}>
      {!isEmpty(url) && <Iframe type="fullHeight" className={classes.iframe} src={url} />}
    </div>
  );
}

IFrameModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

IFrameModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(IFrameModule);
