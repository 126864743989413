import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isNull from 'lodash/isNull';
import get from 'lodash/get';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { ImageList, ImageListItem, withStyles } from '@material-ui/core';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { breakPoints } from '../../../../constants';

import Media from '../Media';

const styles = () => ({
  root: {
    display: 'block',
    marginBottom: '40px',
  },
});

function MediaGalleryModule({ classes, className, data }) {
  const inspectorProps = useContentfulInspectorMode();
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();

  const galleryImages = get(data, 'galleryImages');
  const usePreview = get(data, 'usePreview');

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.sm) {
        setIsMobile(false);
      }
      if (width < breakPoints.sm) {
        setIsMobile(true);
      }
    }
    return () => {
      return null;
    };
  }, [width]);

  return (
    <div
      className={clsx(classes.root, className)}
      {...inspectorProps({
        entryId: get(data, 'contentful_id'),
        fieldId: 'galleryImages',
        locale: 'en-US',
      })}>
      <div className={classes.root}>
        <ImageList className={classes.gridList} rowHeight="auto" cols={isMobile ? 2 : 4}>
          {map(galleryImages, (galleryImage, index) => {
            const galleryImageId = get(galleryImage, 'contentful_id');
            const galleryImageKey = `${galleryImageId}-${index}`;
            return (
              <ImageListItem key={galleryImageKey} className={classes.tile}>
                <Media data={galleryImage} isMobile={isMobile} usePreview={isNull(usePreview) ? true : usePreview} />
              </ImageListItem>
            );
          })}
        </ImageList>
      </div>
    </div>
  );
}

MediaGalleryModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MediaGalleryModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(MediaGalleryModule);
