import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get, map, concat, compact, isEmpty, slice, size, take } from 'lodash';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Container, Typography, withStyles } from '@material-ui/core';

import ArticlePage from './ArticlePage';
import BackgroundImage from '../../../shared/BackgroundImage';
import ContentListSlider from '../../../shared/Slider';
import LoadMoreButton from '../../../shared/LoadMoreButton';
import { breakPoints } from '../../../../constants';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: ({ data }) => {
        const backgroundImage = get(data, 'backgroundImage');
        if (backgroundImage) {
          return '90px 0';
        }
        return theme.spacing(2, 0);
      },
    },
    '@media print': {
      display: 'none',
    },
  },
  minusMargin: {
    [theme.breakpoints.up('1000')]: {
      margin: '0 -100px',
    },
    [theme.breakpoints.up('1240')]: {
      margin: '0 -220px',
    },
  },
  noMinusMargin: {
    margin: '0',
  },
  additionalContent: {
    alignItems: 'baseline',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    padding: theme.spacing(3),
    width: '100%',
    marginRight: '2px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: '3%',
      marginBottom: theme.spacing(0),
      padding: theme.spacing(5, 8),
      width: '48.5%',
    },
  },
  slider: {
    margin: 'auto',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: ({ data }) => {
        const additionalContent = get(data, 'additionalContent');
        if (additionalContent) {
          return '48.5%';
        }
        return '100%';
      },
    },
  },
  headingWrapper: {},
  heading: {
    margin: '32px auto 24px',
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: ({ data, gap }) => {
      const listAppearance = get(data, 'listAppearance') ? get(data, 'listAppearance') : 'Grid';
      const hideText = !!listAppearance.includes('Slider with images only');

      if (hideText || isEmpty(gap)) {
        return '0';
      }
      return `${gap}px`;
    },
    maxWidth: ({ data }) => {
      const fullWidth = get(data, 'fullWidth');
      if (fullWidth) {
        return '100%';
      }
      return 'auto';
    },
    padding: ({ data }) => {
      const fullWidth = get(data, 'fullWidth');
      const listAppearance = get(data, 'listAppearance');
      if (fullWidth || (listAppearance && listAppearance.includes('Slider with images only'))) {
        return '0';
      }
      return '0';
    },
  },
});

// eslint-disable-next-line react/prop-types
function LatestArticlesModule({ classes, className, data, richText: RichText, elementsPerRowMobile, ...otherProps }) {
  const inspectorProps = useContentfulInspectorMode();
  const contentListRef = useRef(null);
  const [enableMinusMargin, setEnableMinusMargin] = useState(true);
  const [page, setPage] = useState(1);
  const gridContentPerPage = 8;
  const listAppearance = get(data, 'listAppearance') ? get(data, 'listAppearance') : 'Grid';

  const slider = !!(
    listAppearance.includes('Slider with text and images') || listAppearance.includes('Slider with images only')
  );

  const hideText = !!listAppearance.includes('Slider with images only');
  const showTitle = !!get(data, 'showTitle');

  const title = get(data, 'title');

  const altText = get(data, 'backgroundImage.imageAltText');
  const publicId = get(data, 'backgroundImage.cloudinaryImage.0.public_id');

  const setMargin = () => {
    const moduleWidth = get(contentListRef, 'current.clientWidth');
    if (moduleWidth > breakPoints.lg) {
      setEnableMinusMargin(false);
    }
  };

  const articlePages = get(data, 'articlePages') ? get(data, 'articlePages') : [];
  const categoryPages = get(data, 'categoryPages') ? get(data, 'categoryPages') : [];
  const brandPages = get(data, 'brandPages') ? get(data, 'brandPages') : [];
  const recipePages = get(data, 'recipePages') ? get(data, 'recipePages') : [];

  const combinedContent = compact(concat([], articlePages, categoryPages, brandPages, recipePages));
  const [gridContent, setGridContent] = useState(take(combinedContent, gridContentPerPage));

  const additionalContent = get(data, 'additionalContent');
  const fullWidth = get(data, 'fullWidth');

  const sliderControls =
    get(data, 'sliderControls') && get(data, 'sliderControls').includes('Dots') ? 'dots' : 'arrows';

  const _elementsPerRowMobile = additionalContent ? 1 : elementsPerRowMobile;

  let elementsPerRowDesktop = additionalContent ? 3 : 4;
  if (fullWidth && !additionalContent) {
    elementsPerRowDesktop = 6;
  }

  const hasNextPage = useMemo(() => {
    return size(combinedContent) - size(gridContent) > 0;
  }, [combinedContent, gridContent]);

  useEffect(() => {
    setGridContent(slice(combinedContent, 0, page * gridContentPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setMargin();
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', setMargin);
      return () => window.removeEventListener('resize', setMargin);
    }
    return () => {};
  }, [contentListRef]);

  const handleLoadMoreClick = useCallback(() => {
    setPage(prev => prev + 1);
  }, []);

  const entryId = get(data, 'contentful_id');

  if (combinedContent.length > 0) {
    return (
      <div
        className={clsx(classes.root, className, enableMinusMargin ? classes.minusMargin : classes.noMinusMargin)}
        ref={contentListRef}>
        {showTitle && (
          <div
            className={classes.headingWrapper}
            {...inspectorProps({
              entryId,
              fieldId: 'title',
            })}>
            <Typography variant="h3" className={classes.heading}>
              {title}
            </Typography>
          </div>
        )}
        <Container
          maxWidth="lg"
          className={classes.container}
          {...inspectorProps({
            entryId,
            fieldId: 'contentType',
            locale: 'en-US',
          })}>
          {additionalContent && <RichText textData={additionalContent} className={classes.additionalContent} />}
          {slider && (
            <ContentListSlider
              hideText={hideText}
              className={classes.slider}
              elementsPerRowMobile={_elementsPerRowMobile}
              elementsPerRowDesktop={elementsPerRowDesktop}
              sliderControls={sliderControls}
              {...otherProps}>
              {map(combinedContent, (articlePage, index) => {
                return (
                  <ArticlePage
                    key={index}
                    data={articlePage}
                    hideText={hideText}
                    elementsPerRowMobile={_elementsPerRowMobile}
                    elementsPerRowDesktop={elementsPerRowDesktop}
                    richText={RichText}
                    {...otherProps}
                  />
                );
              })}
            </ContentListSlider>
          )}
          {!slider && (
            <>
              {map(gridContent, (articlePage, index) => {
                return (
                  <ArticlePage
                    key={index}
                    data={articlePage}
                    hideText={hideText}
                    richText={RichText}
                    elementsPerRowMobile={_elementsPerRowMobile}
                    elementsPerRowDesktop={elementsPerRowDesktop}
                    {...otherProps}
                  />
                );
              })}
            </>
          )}
        </Container>
        {!slider && (
          <LoadMoreButton
            disable={isEmpty(combinedContent)}
            isFetching={isEmpty(gridContent)}
            hasElements={!isEmpty(gridContent)}
            hasNextPage={hasNextPage}
            handleLoadMoreClick={handleLoadMoreClick}
          />
        )}
        <BackgroundImage altText={altText} publicId={publicId} />
      </div>
    );
  }
  return null;
}

LatestArticlesModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

LatestArticlesModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(LatestArticlesModule);
