import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';
import { get, isEqual } from 'lodash';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import LayoutOne from '../LayoutOne';
import LayoutTwo from '../LayoutTwo';
import LayoutThree from '../LayoutThree';

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0),
    [theme.breakpoints.up('sm')]: { margin: theme.spacing(3, 0) },
  },
  layoutOne: {},
  layoutTwo: {},
  layoutThree: {},
});

function InspirationalAreaModule({ classes, className, data, richText: RichText }) {
  const inspectorProps = useContentfulInspectorMode();
  const appearance = get(data, 'appearance.0');

  const layout1 = 'Masonry grid 1';
  const layout2 = 'Masonry grid 2';
  const layout3 = 'Masonry grid 3';

  return (
    <div
      className={clsx(classes.root, className)}
      {...inspectorProps({
        entryId: get(data, 'contentful_id'),
        fieldId: 'inspirationalElements',
        locale: 'en-US',
      })}>
      {isEqual(appearance, layout1) && <LayoutOne className={classes.layoutOne} data={data} richText={RichText} />}
      {isEqual(appearance, layout2) && <LayoutTwo className={classes.layoutTwo} data={data} richText={RichText} />}
      {isEqual(appearance, layout3) && <LayoutThree className={classes.layoutThree} data={data} richText={RichText} />}
    </div>
  );
}

InspirationalAreaModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
};

InspirationalAreaModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(InspirationalAreaModule);
