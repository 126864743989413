import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, isEqual } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';

import { contentfulContentTypes } from '../../../../constants';

import ContentColumn from './ContentColumn';

const styles = () => ({
  root: {
    display: 'block',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  columnRoot: {
    justifyContent: ({ rowData }) => {
      const columnVerticalAlignment = get(rowData, 'columnVerticalAlignment.0', null);
      if (isEqual(columnVerticalAlignment, 'Top')) {
        return 'flex-start';
      }
      if (isEqual(columnVerticalAlignment, 'Bottom')) {
        return 'flex-end';
      }
      return 'center';
    },
  },
});

function ContentColumns({ classes, className, rowData, pageData }) {
  const contentColumns = get(rowData, 'columns');
  const fullWidth = get(rowData, 'fullWidth');

  const contentType = get(contentColumns, '0.content.references.0.__typename');
  const isHeroImage = isEqual(contentType, contentfulContentTypes.HeroImageModule);
  if (isHeroImage || fullWidth) {
    return (
      <div className={clsx(classes.root, className)}>
        {map(contentColumns, columnData => {
          const contentColumnId = get(columnData, 'id');
          return (
            <ContentColumn
              key={contentColumnId}
              columnData={columnData}
              rowData={rowData}
              pageData={pageData}
              classes={{ root: classes.columnRoot }}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container className={classes.container} disableGutters>
        {map(contentColumns, (columnData, i) => {
          return (
            <ContentColumn
              key={i}
              columnData={columnData}
              rowData={rowData}
              pageData={pageData}
              classes={{ root: classes.columnRoot }}
            />
          );
        })}
      </Container>
    </div>
  );
}

ContentColumns.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ContentColumns.defaultProps = {
  classes: {},
  className: null,
  rowData: null,
  pageData: null,
};

export const query = graphql`
  fragment ContentColumnFragment on ContentfulColumnStructure {
    id
    contentful_id
    title
    textAlign
    mobileOrder
    columnStyle {
      ... on ContentfulCustomStyle {
        ...ContentfulCustomStyleFragment
      }
    }
    backgroundImage {
      imageAltText
      imageTitleText
      cloudinaryImage {
        secure_url
        public_id
      }
    }
    content {
      ...ContentFragment
    }
    backgroundImagePosition
    identifier
  }
`;

export default withStyles(styles)(ContentColumns);
