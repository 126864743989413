/* eslint-disable no-useless-escape */
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import { isSSR } from '../../../../utils/windowUtils';
import { viewVideo } from '../../../../utils/analyticUtils';

const styles = theme => ({
  root: {
    margin: '40px 0',
    '@media print': {
      display: 'none',
    },
  },
  frame: {
    maxWidth: '100%',
    height: '250px',

    [theme.breakpoints.up('sm')]: {
      height: '400px',
    },
    [theme.breakpoints.up('md')]: {
      height: '500px',
    },
  },
  removePointerEvent: {
    pointerEvents: 'none',
  },
  buttonWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '3',
  },
});

const youtubeIdPattern =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/; // eslint-disable-line max-len

function VideoModule({ classes, className, data }) {
  const inspectorProps = useContentfulInspectorMode();
  const title = get(data, 'title');
  const youtubeUrl = get(data, 'youTubeUrl');

  const youtubeId = useMemo(() => {
    let videoId = null;
    if (!isEmpty(youtubeUrl)) {
      videoId = get(youtubeUrl.match(youtubeIdPattern), 1);
    }
    return videoId;
  }, [youtubeUrl]);

  const onIframeClickHandler = () => {
    if (!isSSR()) {
      if (document.activeElement.id === youtubeId) {
        viewVideo();
      }
    }
  };

  useEffect(() => {
    if (!isSSR()) {
      window.addEventListener('blur', onIframeClickHandler);
      return () => {
        window.removeEventListener('blur', onIframeClickHandler);
      };
    }
    return () => null;
  });

  if (isEmpty(youtubeId)) {
    return null;
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...inspectorProps({
        entryId: get(data, 'contentful_id'),
        fieldId: 'youTubeUrl',
      })}>
      <iframe
        id={youtubeId}
        title={title}
        type="text/html"
        src={`https://www.youtube.com/embed/${youtubeId}?rel=0&start=0`}
        width="100%"
        frameBorder="0"
        allowFullScreen="allowfullscreen"
        className={clsx(classes.frame, classes._removePointerEvent)}
      />
      {/*
      <div>
        <Typography variant="caption">{title}</Typography>
      </div>
      */}
    </div>
  );
}

VideoModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

VideoModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(VideoModule);
