/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import { Typography, withStyles } from '@material-ui/core';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { siteId } from '../../../../sites';
import { siteIds } from '../../../../constants';

import Link from '../../../navigation/Link';
import Visible from '../../../shared/Visible';

import ThumbnailImage from '../../../shared/ThumbnailImage';
import { clickAmazonAffiliateLink } from '../../../../utils/analyticUtils';

const styles = theme => ({
  root: {
    marginBottom: '40px',
    minHeight: '270px',
    backgroundColor: theme.palette.background.linkBlock,
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px',
    },
    '@media print': {
      backgroundColor: 'transparent',
      border: '2px solid darkgray',
      minHeight: '0',
      pageBreakInside: 'avoid',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    minHeight: '270px',
    '@media print': {
      flexDirection: 'row',
    },
  },
  left: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  right: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  textOnly: {
    justifyContent: 'center',
  },
  link: {
    color: 'inherit',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px',

    [theme.breakpoints.up('sm')]: {
      padding: '16px',
      ...get(theme, ['linkBlockModule', 'imageWrapper'], {}),
    },
  },
  image: {
    borderRadius: '0',
    '@media print': {
      maxWidth: '200px',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '8px',
    marginTop: '16px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px',
      marginTop: '0px',
      ...get(theme, ['linkBlockModule', 'textWrapper'], {}),
    },
  },
  center: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      ...get(theme, 'linkBlockModule.center', {}),
    },
  },
});

function LinkBlockModule({ classes, className, data, richText: RichText, showTitle }) {
  const inspectorProps = useContentfulInspectorMode();
  const contentfulId = get(data, 'contentful_id');
  const image = get(data, 'image');
  const title = get(data, 'title');
  const orientation = get(data, 'blockOrientation');
  const description = get(data, 'description');
  const linkTarget = get(data, 'linkTarget');
  const openLinkTo = get(data, 'openLinkTo');
  if (isEmpty(image) && isEmpty(description)) {
    return null;
  }

  const handleClick = () => {
    if (isEqual(siteId, siteIds.Koskenkorva) && includes(get(description, 'raw'), 'https://www.amazon.de')) {
      clickAmazonAffiliateLink();
    }
  };
  const handleImageClick = () => {
    if (isEqual(siteId, siteIds.Koskenkorva) && includes(linkTarget, 'https://www.amazon.de')) {
      clickAmazonAffiliateLink();
    }
  };

  return (
    <article className={clsx(classes.root, className)}>
      <div
        className={clsx(
          classes.content,
          orientation ? classes.left : classes.right,
          isEmpty(image) && classes.textOnly,
        )}>
        <Visible hidden={isEmpty(image)}>
          <div className={classes.imageWrapper}>
            <Visible hidden={!isEmpty(linkTarget)}>
              <ThumbnailImage
                data={image}
                className={classes.image}
                transformation="CardImage-LG-LG"
                height="auto"
                width="500"
                {...inspectorProps({
                  entryId: contentfulId,
                  fieldId: 'image',
                })}
              />
            </Visible>
            <Visible hidden={isEmpty(linkTarget)}>
              <Link to={linkTarget} target={openLinkTo ? '_self' : '_blank'} onClick={handleImageClick}>
                <ThumbnailImage
                  data={image}
                  className={classes.image}
                  transformation="CardImage-LG-LG"
                  height="auto"
                  width="500"
                  {...inspectorProps({
                    entryId: contentfulId,
                    fieldId: 'image',
                  })}
                />
              </Link>
            </Visible>
          </div>
        </Visible>
        <div className={classes.text} onClick={handleClick}>
          {showTitle && (
            <Typography
              variant="h3"
              className={classes.title}
              {...inspectorProps({
                entryId: contentfulId,
                fieldId: 'title',
              })}>
              {title}
            </Typography>
          )}
          <div
            {...inspectorProps({
              entryId: contentfulId,
              fieldId: 'description',
            })}>
            <RichText textData={description} classes={{ root: classes.center }} />
          </div>
        </div>
      </div>
    </article>
  );
}

LinkBlockModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  richText: PropTypes.object,
  showTitle: PropTypes.bool,
};

LinkBlockModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
  showTitle: false,
};

export default withStyles(styles)(LinkBlockModule);
