import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { map, get } from 'lodash';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import { Typography, withStyles } from '@material-ui/core';
import ContentListElement from './ContentListElement';
import PersonListElement from './PersonListElement';
import ProductListElement from './ProductListElement';
import ContentListSlider from '../../../shared/Slider';
import { breakPoints, contentfulContentTypes } from '../../../../constants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    gap: ({ gap }) => {
      if (gap) {
        return `${gap}px`;
      }
      return '0';
    },
    '@media print': {
      display: 'none',
    },
  },
  slider: {},
  minusMargin: {
    margin: '0 0 16px 0',
    [theme.breakpoints.up('1000')]: {
      margin: '0 -100px 24px -100px',
    },
    [theme.breakpoints.up('1240')]: {
      margin: '0 -220px 24px -220px',
    },
  },
  noMinusMargin: {
    margin: '0 0 16px 0',
  },
  titleWrapper: {},
  title: {
    margin: '3rem auto 1.5rem',
  },
});

// eslint-disable-next-line react/prop-types
function ContentListModule({ classes, className, data, richText: RichText, ...otherProps }) {
  const inspectorProps = useContentfulInspectorMode();
  const contentListRef = useRef(null);
  const [enableMinusMargin, setEnableMinusMargin] = useState(true);

  const setMargin = () => {
    const moduleWidth = get(contentListRef, 'current.clientWidth');
    if (moduleWidth > breakPoints.lg) {
      setEnableMinusMargin(false);
    }
  };

  useEffect(() => {
    setMargin();
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', setMargin);
    }
    return () => {
      window.removeEventListener('resize', setMargin);
    };
  }, [contentListRef]);

  const contentListElements = get(data, 'contentList');
  const showTitle = !!get(data, 'showTitle');
  const listAppearance = get(data, 'listAppearance') ? get(data, 'listAppearance') : 'Slider';

  const slider = !!(
    listAppearance.includes('Slider with text and images') ||
    listAppearance.includes('Slider with images only') ||
    listAppearance.includes('Slider')
  );

  const hideText = !!listAppearance.includes('Slider with images only');

  const title = get(data, 'title');

  const entryId = get(data, 'contentful_id');

  return (
    <div>
      {showTitle && (
        <div
          className={classes.titleWrapper}
          {...inspectorProps({
            entryId,
            fieldId: 'title',
          })}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        </div>
      )}
      <div
        className={clsx(classes.root, className, enableMinusMargin ? classes.minusMargin : classes.noMinusMargin)}
        ref={contentListRef}
        {...inspectorProps({
          entryId,
          fieldId: 'contentList',
          locale: 'en-US',
        })}>
        {slider && (
          <ContentListSlider {...otherProps} hideText={hideText} className={classes.slider}>
            {map(contentListElements, (contentListElement, index) => {
              const contentListElementId = get(contentListElement, 'contentful_id');
              const pageType =
                get(contentListElement, 'pageReference.__typename') ?? get(contentListElement, '__typename');

              const contentListElementKey = `${contentListElementId}-${index}`;
              if (pageType === contentfulContentTypes.ProductReference) {
                <ProductListElement
                  key={contentListElementKey}
                  data={contentListElement}
                  richText={RichText}
                  slider
                  hideText={hideText}
                  {...otherProps}
                />;
              }
              if (pageType === contentfulContentTypes.ContactPersonModule) {
                return <PersonListElement key={contentListElementKey} data={contentListElement} slider={slider} />;
              }
              return (
                <ContentListElement
                  key={contentListElementKey}
                  data={contentListElement}
                  richText={RichText}
                  slider
                  hideText={hideText}
                  {...otherProps}
                />
              );
            })}
          </ContentListSlider>
        )}
        {!slider &&
          map(contentListElements, (contentListElement, index) => {
            const contentListElementId = get(contentListElement, 'contentful_id');
            const contentListElementKey = `${contentListElementId}-${index}`;
            const pageType =
              get(contentListElement, 'pageReference.__typename') ?? get(contentListElement, '__typename');

            if (pageType === contentfulContentTypes.ProductReference) {
              <ProductListElement
                key={contentListElementKey}
                data={contentListElement}
                richText={RichText}
                slider
                {...otherProps}
              />;
            }
            if (pageType === contentfulContentTypes.ContactPersonModule) {
              return <PersonListElement key={contentListElementKey} data={contentListElement} slider={slider} />;
            }

            return (
              <ContentListElement
                key={contentListElementKey}
                data={contentListElement}
                richText={RichText}
                {...otherProps}
              />
            );
          })}
      </div>
    </div>
  );
}

ContentListModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ContentListModule.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  data: null,
};

export default withStyles(styles)(ContentListModule);
