import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, isEmpty } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Button from '../../../shared/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  category: {
    backgroundColor: ({ outlined }) => {
      if (outlined) {
        return theme.palette.category.outlined.background;
      }
      return 'transparent';
    },
    '@media print': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    color: ({ outlined }) => {
      if (outlined) {
        return theme.palette.category.outlined.text;
      }
      return theme.palette.category.text;
    },
    fontSize: '12px',
    marginRight: theme.spacing(2),
    marginBottom: '4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    background: 'none',
    padding: ({ outlined }) => {
      if (outlined) {
        return theme.spacing(1);
      }
      return '0';
    },
    border: '0px',
    '&:hover': {
      color: theme.palette.category.hoverText,
      border: '0px',
      boxShadow: 'none',

      backgroundColor: 'transparent',
    },
  },
});

function Categories({ classes, className, categoriesData, ...otherProps }) {
  if (isEmpty(categoriesData)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)} {...otherProps}>
      {map(categoriesData, (category, index) => {
        const categoryId = get(category, 'contentful_id');
        const categoryKey = `${categoryId}-${index}`;
        const title = get(category, 'title');
        const fullPath = get(category, 'fullPath');

        return (
          <div key={categoryKey}>
            <Button to={fullPath} size="small" className={classes.category}>
              {title}
            </Button>
          </div>
        );
      })}
    </div>
  );
}

Categories.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  outlined: PropTypes.bool,
  categoriesData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Categories.defaultProps = {
  classes: {},
  className: null,
  outlined: false,
  categoriesData: null,
};

export default withStyles(styles)(Categories);

export const query = graphql`
  fragment CategoryFragment on ContentfulCategory {
    id
    title
    menuTitle
    fullPath
    categoryIcon {
      id
      imageAltText
      imageTitleText
      openLinkTo
      cloudinaryImage {
        public_id
      }
    }
  }
`;
