import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles, Typography } from '@material-ui/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import CloudinaryImage from '../../../shared/CloudinaryImage';
import Link from '../../../navigation/Link';
import PhotoCredit from '../../../shared/PhotoCredit';
import ImageSpotLink from '../../../shared/ImageSpotLink/ImageSpotLink';

const styles = theme => ({
  root: {
    display: 'block',

    padding: ({ data }) => {
      const mobilePaddingTop = get(data, 'mobilePaddingTop');
      const mobilePaddingRight = get(data, 'mobilePaddingRight');
      const mobilePaddingBottom = get(data, 'mobilePaddingBottom');
      const mobilePaddingLeft = get(data, 'mobilePaddingLeft');

      if (
        !isEmpty(mobilePaddingTop) ||
        !isEmpty(mobilePaddingRight) ||
        !isEmpty(mobilePaddingBottom) ||
        !isEmpty(mobilePaddingLeft)
      ) {
        return `${mobilePaddingTop || '0'} ${mobilePaddingRight || '0'} ${mobilePaddingBottom || '0'} ${
          mobilePaddingLeft || '0'
        }`;
      }
      return '0 0 40px 0';
    },

    [theme.breakpoints.up('md')]: {
      padding: ({ data }) => {
        const desktopPaddingTop = get(data, 'desktopPaddingTop');
        const desktopPaddingRight = get(data, 'desktopPaddingRight');
        const desktopPaddingBottom = get(data, 'desktopPaddingBottom');
        const desktopPaddingLeft = get(data, 'desktopPaddingLeft');

        if (
          !isEmpty(desktopPaddingTop) ||
          !isEmpty(desktopPaddingRight) ||
          !isEmpty(desktopPaddingBottom) ||
          !isEmpty(desktopPaddingLeft)
        ) {
          return `${desktopPaddingTop || '0'} ${desktopPaddingRight || '0'} ${desktopPaddingBottom || '0'} ${
            desktopPaddingLeft || '0'
          }`;
        }

        return '0 0 40px 0';
      },
    },
  },
  imageWrapper: {
    position: 'relative',
  },
  image: {
    display: 'block',
    height: 'auto',
    width: '100%',
    maxWidth: '100%',
  },
  caption: {
    padding: '.625rem 0',
  },
});

function ImageModule({ classes, className, data, ...otherProps }) {
  const inspectorProps = useContentfulInspectorMode();
  const contentfulId = get(data, 'contentful_id');
  const altText = get(data, 'imageAltText');
  const titleText = get(data, 'imageTitleText');
  const caption = get(data, 'imageCaption');
  const imageLink = get(data, 'imageLink');
  const openLinkTo = get(data, 'openLinkTo');
  const publicId = get(data, 'cloudinaryImage.0.public_id');
  const imageFormat = get(data, 'cloudinaryImage.0.format');
  const photoCred = get(data, 'photoCredit');
  const photoCredColor = get(data, 'photoCreditColor', null);
  const imageSpotLinks = get(data, 'imageSpotLink');

  const variants = useMemo(
    () => [
      {
        transformation: 'FullWidthImage-XS-IN',
        width: 430,
      },

      {
        transformation: 'FullWidthImage-SM-IN',
        width: 768,
      },

      {
        transformation: 'FullWidthImage-MD-IN',
        width: 1024,
      },

      {
        transformation: 'FullWidthImage-LG-IN',
        width: 1440,
      },
    ],
    [],
  );

  if (isEmpty(publicId)) {
    return null;
  }

  if (isEqual(imageFormat, 'svg')) {
    const imageName = `${publicId}.${imageFormat}`;
    return (
      <div className={clsx(classes.root, className)}>
        <div
          className={classes.imageWrapper}
          {...inspectorProps({
            entryId: contentfulId,
            fieldId: 'cloudinaryImage',
          })}>
          <CloudinaryImage
            publicId={imageName}
            alt={altText}
            title={titleText}
            width="100%"
            height="auto"
            format="svg"
          />
          <PhotoCredit photoCredit={photoCred} color={photoCredColor} />
          {map(imageSpotLinks, spotLinkData => {
            const id = get(spotLinkData, 'id');
            return <ImageSpotLink data={data} spotLinkData={spotLinkData} {...otherProps} key={id} />;
          })}
        </div>
      </div>
    );
  }

  if (isEmpty(imageLink)) {
    return (
      <div className={clsx(classes.root, className)}>
        <div
          className={classes.imageWrapper}
          {...inspectorProps({
            entryId: contentfulId,
            fieldId: 'cloudinaryImage',
          })}>
          <CloudinaryImage
            publicId={publicId}
            variants={variants}
            alt={altText}
            title={titleText}
            width="100%"
            height="auto"
          />
          <PhotoCredit photoCredit={photoCred} color={photoCredColor} />
          {map(imageSpotLinks, spotLinkData => {
            const id = get(spotLinkData, 'id');
            return <ImageSpotLink data={data} spotLinkData={spotLinkData} {...otherProps} key={id} />;
          })}
        </div>

        {caption && (
          <div
            className={classes.caption}
            {...inspectorProps({
              entryId: contentfulId,
              fieldId: 'imageCaption',
            })}>
            <Typography variant="caption">{caption}</Typography>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Link to={imageLink} target={!openLinkTo ? '_blank' : ''}>
        <div
          className={classes.imageWrapper}
          {...inspectorProps({
            entryId: contentfulId,
            fieldId: 'cloudinaryImage',
          })}>
          <CloudinaryImage
            publicId={publicId}
            variants={variants}
            alt={altText}
            title={titleText}
            width="100%"
            height="auto"
          />
          <PhotoCredit photoCredit={photoCred} color={photoCredColor} />
          {map(imageSpotLinks, spotLinkData => {
            const id = get(spotLinkData, 'id');
            return <ImageSpotLink data={data} spotLinkData={spotLinkData} {...otherProps} key={id} />;
          })}
        </div>

        {caption && (
          <div
            className={classes.caption}
            {...inspectorProps({
              entryId: contentfulId,
              fieldId: 'imageCaption',
            })}>
            <Typography variant="caption">{caption}</Typography>
          </div>
        )}
      </Link>
    </div>
  );
}

ImageModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ImageModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(ImageModule);
